import React, { useEffect } from 'react'
import { useSpring, animated } from 'react-spring'

export default props => {

    // TODO - ADD dots, and arrows for navigation and use react-swipeable to add swipe functionality on mobile.    
    const [sliderProps, set, stop] = useSpring(() => ({ transform: `translate3D(0%,0%,0)` }))
    let currentImage = 0

    useEffect(() => {

        let width = window.innerWidth
        let multiplier
        let transform
        let dimension
        window.addEventListener('resize', () => {
            width = window.innerWidth
        })

        const moveSlider = () => {
            currentImage++

            if (currentImage > props.images.length - 1) {
                currentImage = 0
            }

            if (width >= 992) {
                multiplier = Math.floor(100 / props.images.length)
                dimension = currentImage * multiplier
                transform = { transform: `translate3d(0, -${dimension}%, 0)` }
            } else {
                multiplier = 100
                dimension = currentImage * multiplier
                transform = { transform: `translate3d(-${dimension}%, 0, 0)` }
            }


            set(transform)

        }

        const slider = setInterval(moveSlider, 3000)


        return () => {
            clearInterval(slider)
            stop()
        }
    })

    return (
        <animated.div style={sliderProps} className="img-container">
            {props.images.map((image, i) => <img src={image.file.url} key={i} style={{ zIndex: i }} alt={`${image.description} - Image #${i + 1}`} className="gallery-pic" />)}
        </animated.div>
    )
}