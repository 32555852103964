import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Slider from '../components/slider'
import SEO from '../components/seo'

export default function template(props) {
  const post = props.data.contentfulPortfolioPiece

  return (
    <Layout logoActive={false}>
      <SEO title={post.title} />
      <section className="row project_page">
        <div className="col-xl-4 col-lg-5 col-md-12 project_info">
          <h2 className="projectTitle">{post.title}</h2>
          <h3 className="projectSubTitle">{post.subtitle}</h3>
          <div className="projectDescription" dangerouslySetInnerHTML={{ __html: post.content.childContentfulRichText.html }}></div>
          <ul className="projectStats">
            <li className="projectRole">Role: <span>{post.role}</span></li>
            <li className="projectStack">Team: <span>{post.team.map((member, i) => {
              if (i + 1 >= post.team.length) {
                return <React.Fragment> {member}</React.Fragment>
              } else {
                return <React.Fragment> {member},</React.Fragment>
              }


            })}</span></li>
            <li className="projectStack">Stack: <span>{post.stack.map((tech, i) => {
              if (i + 1 >= post.stack.length) {
                return <React.Fragment> {tech}</React.Fragment>
              } else {
                return <React.Fragment> {tech},</React.Fragment>
              }


            })}</span></li>
            <li className="projectAgency">Agency: <span>{post.agency}</span></li>
          </ul>
          <Link to='/portfolio' state={{ logoActive: false }} className="btn btn-primary pull-right back-btn">Back</Link>
        </div>
        <div className="col-xl-8 col-lg-7 col-md-12 slideshow">
          <div className="slideshow-container">
            <Slider images={post.images} title={post.title} />
          </div>
        </div>
      </section>

    </Layout>

  )
}

export const postQuery = graphql`
    query portfolioByPath($slug: String!) {
  contentfulPortfolioPiece(slug: {eq: $slug}) {
    description {
      description
    }
    content {
      content
      childContentfulRichText {
        html
      }
    }
    agency
    createdAt
    id
    images {
      file {
        url
      }
      title
      description
    }
    slug
    role
    subtitle
    team
    stack
    title
  }
    }
`

